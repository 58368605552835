import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w: object | string
  h: object | string
  color: string
  transition?: string
  _hover?: object
}


export const VesterIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 16 23" { ...props }>
      <path
        fill="currentColor"
        d="M16 0L5.52727 12.8408H10.2303L7.97576 19.4933H7.78182L5.52727 12.8408L4.12121 8.66368H0V8.68946L5.26061 23H5.28485H10.3758H10.4L15.7091 8.66368H12.1212L16 0Z"
      />
    </Icon>
  )
}
