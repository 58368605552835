import * as React from "react";
import {
  Container,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";


interface Props {
  heading: string
  lastUpdated: string
};


export const LegalHero: React.FC<Props> = ( props ) => {
  return (
    <Container
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '776px'
      }}
      w="100%"
      padding={{
        base: '120px 1rem 64px',
        lg: '257px 0 84px'
      }}
    >
      <Stack
        direction="column"
        spacing={ 6 }
      >
        <Heading
          as="h1"
          color="accent.900"
          fontSize="48px"
          fontWeight={ 400 }
          lineHeight="54px"
        >
          { props.heading }
        </Heading>
        <Text
          fontSize="12px"
          lineHeight="16px"
        >
          Last Updated: { props.lastUpdated }
        </Text>
      </Stack>
    </Container>
  )
}
