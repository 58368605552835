import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";


interface Props {
  headerTopVisible?: boolean
};


export const SiteNav: React.FC<Props> = ( props ) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack
      as="nav"
      direction={{
        base: 'column',
        lg: 'row'
      }}
      align={{
        base: 'flex-start',
        lg: 'center'
      }}
      spacing={{
        base: 4,
        xl: 8
      }}
      h={{
        lg: '72px'
      }}
    >
      <Button
        as={ GatsbyLink }
        to="/about"
        variant="link"
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Our Story
      </Button>
      <Button
        as={ GatsbyLink }
        to="/services"
        variant="link"
        display={{
          base: 'inline-flex',
          lg: 'none'
        }}
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Services
      </Button>
      <Button
        variant="link"
        rightIcon={ <ChevronDownIcon /> }
        display={{
          base: 'none',
          lg: 'inline-flex'
        }}
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
        onClick={ onOpen }
      >
        Services
      </Button>
      <Drawer
        isOpen={ isOpen }
        onClose={ onClose }
        placement="top"
      >
        <DrawerOverlay />
        <DrawerContent
          maxW="100%"
          background="white"
        >
          <DrawerBody
            padding={ props.headerTopVisible ?
              '129px 0 0' :
              '72px 0 0'
            }
          >
            <Container
              maxW={{
                base: '100%',
                sm: '640px',
                md: '768px',
                lg: '1024px',
                xl: '1280px',
                '2xl': '1536px'
              }}
              w="100%"
              padding="14px 1rem 72px"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing="72px"
                h="171px"
              >
                <Stack
                  direction="column"
                  spacing={ 2 }
                >
                  <Heading
                    as="h4"
                  >
                    Your Estimate
                  </Heading>
                  <Text>
                    Get Your Free Estimate Today
                  </Text>
                  <Box>
                    <Button
                      as={ GatsbyLink }
                      to="/estimate"
                      variant="outline"
                      color="primary.900"
                      borderColor="primary.900"
                      borderRadius="20px"
                      _hover={{
                        background: 'primary.900',
                        color: 'white'
                      }}
                      _focus={{
                        boxShadow: 'none !important'
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </Stack>
                <Divider
                  orientation="vertical"
                />
                <Stack
                  direction="column"
                  align="flex-start"
                  spacing={ 4 }
                >
                  <Button
                    as={ GatsbyLink }
                    to="/services"
                    variant="link"
                    color="accent.900"
                    borderRadius="0"
                    fontSize="18px"
                    fontWeight={ 400 }
                    _hover={{
                      color: 'primary.900',
                      textDecoration: 'none'
                    }}
                    _focus={{
                      boxShadow: 'none !important'
                    }}
                  >
                    All Services
                  </Button>
                  <Button
                    as={ GatsbyLink }
                    to="/services/residential"
                    variant="link"
                    color="accent.900"
                    borderRadius="0"
                    fontSize="18px"
                    fontWeight={ 400 }
                    _hover={{
                      color: 'primary.900',
                      textDecoration: 'none'
                    }}
                    _focus={{
                      boxShadow: 'none !important'
                    }}
                  >
                    Residential
                  </Button>
                  <Button
                    as={ GatsbyLink }
                    to="/services/commercial"
                    variant="link"
                    color="accent.900"
                    borderRadius="0"
                    fontSize="18px"
                    fontWeight={ 400 }
                    _hover={{
                      color: 'primary.900',
                      textDecoration: 'none'
                    }}
                    _focus={{
                      boxShadow: 'none !important'
                    }}
                  >
                    Commercial
                  </Button>
                  <Button
                    as={ GatsbyLink }
                    to="/services/industrial"
                    variant="link"
                    color="accent.900"
                    borderRadius="0"
                    fontSize="18px"
                    fontWeight={ 400 }
                    _hover={{
                      color: 'primary.900',
                      textDecoration: 'none'
                    }}
                    _focus={{
                      boxShadow: 'none !important'
                    }}
                  >
                    Industrial
                  </Button>
                </Stack>
              </Stack>
            </Container>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Button
        as={ GatsbyLink }
        to="/blog"
        variant="link"
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Blog
      </Button>
      <Button
        as={ GatsbyLink }
        to="/pest-library"
        variant="link"
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderTop: '1px',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Pest Library
      </Button>
      <Button
        as={ GatsbyLink }
        to="/estimate"
        variant="link"
        display={{
          base: 'inline-flex',
          lg: 'none'
        }}
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Free Estimate
      </Button>
      <Button
        as={ GatsbyLink }
        to="/contact"
        variant="link"
        h={{
          lg: '72px'
        }}
        color="accent.900"
        borderTop="1px"
        borderColor="transparent"
        borderRadius="0"
        fontSize="18px"
        fontWeight={ 400 }
        _hover={{
          color: 'primary.900',
          borderColor: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Contact
      </Button>
    </Stack>
  )
}
