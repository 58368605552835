import * as React from "react";
import { useState } from "react";
import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  IconButton,
  Link,
  VStack
} from "@chakra-ui/react";

import { BurgerIcon } from "../../common/ScalableVectorGraphics";

import { Socials, SiteNav } from "../Navigation";


export const Burger: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState( false );

  const toggleMenu = () => {
    setMenuOpen( !menuOpen );
  };

  return (
    <>
      <IconButton
        aria-label="Open menu"
        icon={ menuOpen ? <CloseIcon /> : <BurgerIcon /> }
        background="transparent"
        color="primary.900"
        border="1px"
        borderColor="primary.900"
        borderRadius="50%"
        _hover={{
          background: 'transparent',
          color: 'primary.900'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
        onClick={ toggleMenu }
      />
      <Box
        position="fixed"
        top="81px"
        right={ 0 }
        bottom={ 0 }
        left={ 0 }
        visibility={ menuOpen ? 'visible' : 'hidden' }
        w="100%"
        h="100vh"
        background="white"
        opacity={ menuOpen ? '1' : '0' }
        transition="all 0.5s ease-in-out"
        zIndex={ 900 }
      >
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px'
          }}
          w="100%"
          padding="2rem 1rem"
        >
          <SiteNav />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            padding="2rem 0 1rem"
          >
            <IconButton
              aria-label="Call now"
              as={ Link }
              href="tel:+18587933471"
              icon={ <PhoneIcon /> }
              w="100px"
              h="32px"
              background="transparent"
              color="primary.900"
              border="1px"
              borderColor="primary.900"
              borderRadius="16px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
              _focus={{
                boxShadow: 'none !important'
              }}
            />
            <Socials
              color="primary.900"
            />
          </Flex>
          <Divider />
          <VStack
            align="flex-start"
            spacing={ 4 }
            padding="1rem 0"
          >
            <Box>
              <Button
                as={ Link }
                href="mailto:info@vesterpest.com"
                variant="link"
                color="accent.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                info@vesterpest.com
              </Button>
            </Box>
            <Box>
              <Button
                as={ Link }
                href="tel:+18587933471"
                variant="link"
                color="accent.900"
                fontSize="18px"
                fontWeight={ 400 }
                lineHeight="21px"
                _hover={{
                  opacity: '0.6',
                  textDecoration: 'none'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                +1 (858) 793-3471
              </Button>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  )
}
