import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Button,
  Flex,
  Image
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { SectionContent } from "../../common/Section";


interface Props {
  heroImage: IGatsbyImageData
};


export const IndexHero: React.FC<Props> = ( props ) => {
  return (
    <Flex
      flexDirection={{
        base: 'column',
        lg: 'row'
      }}
      w="100%"
      h={{
        base: '800px',
        lg: 'auto'
      }}
      padding={{
        base: '120px 0 0',
        lg: '0'
      }}
    >
      <SectionContent
        flex="1 1 0%"
        justifyContent="center"
        alignItems="left"
        componentWidth={{
          base: '100%',
          lg: '50%'
        }}
        componentHeight={{
          base: '100%',
          lg: '800px'
        }}
        textAlign="left"
        heading="Servicing San Diego County for Over 20 Years."
        headingWidth={{
          base: '100%',
          xl: '580px'
        }}
        description={[
          "Whether you need an inspection, treatment, or preventative service, Vester Pest Control is here to help."
        ]}
        descriptionWidth={{
          base: '100%',
          xl: '500px'
        }}
        Button={
          <Box>
            <Button
              as={ GatsbyLink }
              to="/estimate"
              variant="solid"
              size="lg"
              colorScheme="primary"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
            >
              Request a quote
            </Button>
          </Box>
        }
      />
      <Box
        flex="1 1 0%"
        position="relative"
        w="100%"
        h={{
          sm: '300px',
          lg: '800px'
        }}
      >
        <Box
          position="absolute"
          bottom={ 0 }
          right="50%"
          w={{
            base: '208px',
            lg: '433px'
          }}
          h={{
            base: '218px',
            lg: '454px'
          }}
          background="white"
          borderRadius="39px 39px 0 0"
          transform="translate(50%, 0)"
        />
        <Image
          as={ GatsbyImage }
          image={ props.heroImage }
          position="absolute"
          bottom={ 0 }
          right="50%"
          maxW={{
            base: '230px',
            lg: '100%'
          }}
          w={{
            base: '50%',
            sm: '230px',
            lg: 'auto'
          }}
          h="auto"
          transform="translate(50%, 0)"
        />
      </Box>
    </Flex>
  )
}
