import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const MessageFailIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 104 104" { ...props }>
      <path
        fill="currentColor"
        strokeWidth="3"
        d="M33.6915 29.3537L33.6114 29.2235L32.6799 29.3137C31.2281 29.4542 29.9937 30.4293 29.5179 31.8055L33.6915 29.3537ZM33.6915 29.3537C34.4471 29.4879 35.1513 29.8536 35.7004 30.4125L52 46.7121L68.2963 30.4158C68.8692 29.826 69.6221 29.4398 70.4398 29.3221C72.0501 29.089 73.6251 29.9277 74.3308 31.3937C75.0335 32.8518 74.7144 34.5954 73.5436 35.7102L57.2544 51.9994L73.5463 68.2584C74.2625 68.9474 74.6741 69.8938 74.6896 70.8877C74.7051 71.8892 74.3178 72.8543 73.613 73.5669L73.612 73.5678C72.9085 74.2779 71.9487 74.677 70.9475 74.6731L70.9455 74.6731C69.9495 74.6679 68.9958 74.2647 68.2984 73.5523L51.9999 57.2538L35.7011 73.5526C35.0038 74.2649 34.0501 74.6679 33.0544 74.6731L33.0524 74.6731C32.0512 74.677 31.0914 74.2779 30.3878 73.5678L30.3868 73.5669C29.6821 72.8543 29.2947 71.8892 29.3103 70.8877L30.8101 70.911L29.3103 70.8877C29.3258 69.8937 29.7375 68.9472 30.4537 68.2582L46.7454 51.9994L30.4522 35.7061C29.4099 34.6999 29.042 33.1787 29.5178 31.8056L33.6915 29.3537ZM52 1.5C24.1361 1.5 1.5 24.1361 1.5 52C1.5 79.8639 24.1361 102.5 52 102.5C79.8639 102.5 102.5 79.8639 102.5 52C102.5 24.1361 79.8639 1.5 52 1.5ZM52 8.95415C75.7989 8.95415 95.0455 28.2008 95.0455 51.9996C95.0455 75.7984 75.7989 95.0451 52 95.0451C28.2012 95.0451 8.95455 75.7984 8.95455 51.9996C8.95455 28.2008 28.2012 8.95415 52 8.95415Z"
      />
    </Icon>
  )
}
