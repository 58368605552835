import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const ArrowLeftIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 6 8" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.0033 3.32999L4.0653 0.267994C4.12635 0.20569 4.19916 0.156109 4.27949 0.122117C4.35983 0.0881245 4.44611 0.0703953 4.53334 0.0699547C4.62058 0.0695141 4.70703 0.0863707 4.78771 0.11955C4.86838 0.152729 4.94168 0.201573 5.00337 0.263256C5.06505 0.324939 5.11389 0.398239 5.14707 0.478916C5.18025 0.559593 5.19711 0.646047 5.19667 0.73328C5.19623 0.820512 5.1785 0.906792 5.14451 0.98713C5.11051 1.06747 5.06093 1.14027 4.99863 1.20133L2.39863 3.80133L4.99863 6.40133C5.12034 6.52552 5.18812 6.69273 5.18724 6.86661C5.18636 7.0405 5.1169 7.20701 4.99394 7.32997C4.87098 7.45293 4.70447 7.52239 4.53058 7.52327C4.3567 7.52415 4.18949 7.45637 4.0653 7.33466L1.0033 4.27333C0.878316 4.14831 0.808105 3.97877 0.808105 3.80199C0.808105 3.62522 0.878316 3.45568 1.0033 3.33066V3.32999Z"
      />
    </Icon>
  )
}
