import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  Image
} from "@chakra-ui/react"

import { IGatsbyImageData } from "gatsby-plugin-image";


interface Props {
  // Global props
  children?: React.ReactNode
  hasFrame?: boolean
  flex?: object | string
  containerWidth?: object | string
  containerHeight?: object | string
  borderRadius?: object | string

  // Image Container props
  background?: object | string

  // Image props
  image: IGatsbyImageData
  alt: string
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down"
  position?: "top" | "right" | "bottom" | "left"
  imageMaxW?: object | string[] | string
  imageMaxH?: object | string[] | string
};


export const MediaImage: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      flex={ props.flex }
      w={ props.containerWidth }
      h={ props.containerHeight }
    >
      { props.hasFrame && 
        <>
          <Box
            position="absolute"
            top={ 0 }
            right={ 0 }
            w="188px"
            h="171px"
            background="transparent"
            border="1px"
            borderColor="primary.900"
            borderRadius="0px 32px"
            zIndex={ -10 }
          />
          <Box
            position="absolute"
            bottom={ 0 }
            left={ 0 }
            w="188px"
            h="171px"
            background="primary.50"
            borderRadius="0px 32px"
            zIndex={ -10 }
          />
        </>
      }
      <Flex
        justifyContent={
          props.position === "top" ? 'center' :
          props.position === "right" ? 'flex-end' :
          props.position === "bottom" ? 'center' :
          props.position === "left" ? 'flex-start' : 'center'
        }
        alignItems={
          props.position === "top" ? 'flex-start' :
          props.position === "right" ? 'center' :
          props.position === "bottom" ? 'flex-end' :
          props.position === "left" ? 'center' : 'center'
        }
        w={ props.hasFrame ?
          {
            base: 'calc(100% - 20px - 35px)',
            sm: 'calc(100% - 40px - 71px)'
          } :
          "100%"
        }
        h={ props.hasFrame ?
          {
            base: 'calc(100% - 20px - 35px)',
            sm: 'calc(100% - 40px - 69px)'
          } :
          "100%"
        }
        margin={ props.hasFrame ? {
          base: '20px 20px 35px 35px',
          sm: '40px 40px 69px 71px'
        } : 0 }
        background={ props.background }
        borderRadius={ props.borderRadius }
      >
        { props.background &&
          <>
            { props.position === "top" &&
              <Image
                as={ GatsbyImage }
                image={ props.image }
                alt={ props.alt }
                objectFit={ props.objectFit }
                maxW={ props.imageMaxW }
                maxH={ props.imageMaxH }
              />
            }
            { props.position === "right" &&
              <Image
                as={ GatsbyImage }
                image={ props.image }
                alt={ props.alt }
                objectFit={ props.objectFit }
                maxW={ props.imageMaxW }
                maxH={ props.imageMaxH }
              />
            }
            { props.position === "bottom" &&
              <Image
                as={ GatsbyImage }
                image={ props.image }
                alt={ props.alt }
                objectFit={ props.objectFit }
                maxW={ props.imageMaxW }
                maxH={ props.imageMaxH }
              />
            }
            { props.position === "left" &&
              <Image
                as={ GatsbyImage }
                image={ props.image }
                alt={ props.alt }
                objectFit={ props.objectFit }
                maxW={ props.imageMaxW }
                maxH={ props.imageMaxH }
              />
            }
            { !props.position &&
              <Image
                as={ GatsbyImage }
                image={ props.image }
                alt={ props.alt }
                objectFit={ props.objectFit }
                maxW={ props.imageMaxW }
                maxH={ props.imageMaxH }
              />
            }
          </>
        }
        { !props.background &&
          <Image
            as={ GatsbyImage }
            image={ props.image }
            alt={ props.alt }
            objectFit={ props.objectFit }
            w="100%"
            h="100%"
            borderRadius={ props.borderRadius }
          />
        }
      </Flex>
      { props.children }
    </Box>
  )
}
