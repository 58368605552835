import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const MessageSentIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 112 105" { ...props }>
      <path
        fill="currentColor"
        d="M52.4479 0C51.7238 0 50.9979 0.0121416 50.2719 0.0381607C46.4021 0.182994 42.5048 0.765789 38.694 1.78657C10.8992 9.23382 -5.66352 37.9449 1.78306 65.7396C9.22963 93.5344 37.9414 110.097 65.7361 102.651C93.5309 95.2033 110.094 66.4923 102.647 38.6975H102.646C102.364 37.3628 101.546 36.2016 100.383 35.4878C99.2197 34.7731 97.8148 34.5685 96.4965 34.9206C95.1773 35.2727 94.062 36.1512 93.4099 37.3506C92.7586 38.55 92.6285 39.9638 93.05 41.2619C99.1061 63.8636 85.7737 86.9982 63.1727 93.0549C40.571 99.111 17.4364 85.7786 11.3819 63.1776C5.32739 40.5765 18.6582 17.4413 41.2592 11.3846C53.6056 8.07693 66.7938 10.5355 77.12 18.0679V18.067C78.5562 19.1155 80.4435 19.3176 82.0686 18.5969C83.6948 17.8771 84.8135 16.3438 85.0026 14.5763C85.1917 12.808 84.4233 11.0726 82.9863 10.0249C74.0213 3.48572 63.3148 0.0461746 52.4473 0.00110441L52.4479 0ZM106.61 3.69109C105.319 3.76221 104.106 4.33373 103.229 5.28427C90.1204 19 63.9204 47.3394 49.378 62.6722L31.8939 47.2469V47.246C30.5618 46.0665 28.7006 45.6875 27.013 46.2512C25.3244 46.815 24.0661 48.2373 23.7104 49.9814C23.3549 51.7255 23.9568 53.5268 25.2889 54.7062L46.425 73.3559H46.4259C47.3929 74.2128 48.6557 74.6595 49.946 74.6014C51.2374 74.5433 52.4551 73.9856 53.3414 73.0455C67.2481 58.4962 96.6954 26.4811 110.416 12.123V12.1221C111.348 11.1621 111.853 9.86729 111.819 8.52983C111.784 7.19251 111.212 5.92553 110.232 5.0148C109.251 4.10416 107.945 3.62717 106.609 3.69136L106.61 3.69109Z"
      />
    </Icon>
  )
}
