import * as React from "react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";

import { GeoLocationIcon } from "../../common/ScalableVectorGraphics";
import { SectionContent } from "../../common/Section";

import { EstimateForm } from "../Form";


interface Props {
  // Global props
  HeroComponent?: React.ReactNode

  // Heading props
  heading?: string

  // Description props
  description?: string[]
};


export const EstimateHero: React.FC<Props> = ( props ) => {
  return (
    <>
      <SectionContent
        justifyContent="center"
        alignItems={{
          base: 'flex-start',
          lg: 'center'
        }}
        componentWidth="100%"
        componentHeight="auto"
        padding={{
          base: '0 0 32px',
          lg: '0 0 76px'
        }}
        textAlign={{
          base: 'left',
          lg: 'center'
        }}
        heading={ props.heading }
        description={ props.description }
      />
      <Stack
        direction={{
          base: 'column',
          md: 'row'
        }}
        justify={{
          lg: 'center'
        }}
        align={{
          lg: 'flex-start'
        }}
        spacing="32px"
        margin="0 0 40px"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          w="290px"
        >
          <Flex
            justifyContent="flex-start"
            minW="40px"
            w="40px"
            h="auto"
          >
            <GeoLocationIcon
              w="auto"
              h="24px"
              color="primary.900"
            />
          </Flex>
          <Box>
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Address
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              18029 Calle Ambiente, Suite 500, Rancho Santa Fe, CA
            </Text>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              92091
            </Text>
          </Box>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          w="260px"
        >
          <Flex
            justifyContent="flex-start"
            minW="40px"
            w="40px"
            h="auto"
          >
            <EmailIcon
              w="auto"
              h="24px"
              color="primary.900"
            />
          </Flex>
          <Box>
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Email
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              info@vesterpest.com
            </Text>
          </Box>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          w="260px"
        >
          <Flex
            justifyContent="flex-start"
            minW="40px"
            w="40px"
            h="auto"
          >
            <PhoneIcon
              w="auto"
              h="24px"
              color="primary.900"
            />
          </Flex>
          <Box>
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Phone
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              +1 (858) 793-3471
            </Text>
          </Box>
        </Flex>
      </Stack>
      <Flex
        justifyContent="center"
        w="100%"
      >
        <EstimateForm />
      </Flex>
    </>
  )
}
