import * as React from "react";
import {
  Box,
  Heading
} from "@chakra-ui/react";

import {
  Socials
} from "../../Navigation";


interface SocialsColumnProps {
  heading: string
};


export const SocialsColumn: React.FC<SocialsColumnProps> = ( props ) => {
  return (
    <Box
      paddingTop={{
        lg: '14px'
      }}
    >
      <Heading
        as="h6"
        marginBottom="20px"
        color="primary.900"
        fontFamily="fonts.default"
        fontSize="16px"
      >
        { props.heading }
      </Heading>
      <Socials
        justify="space-between"
        color="accent.900"
      />
    </Box>
  )
}
