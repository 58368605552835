import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Link,
  Text,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

import { Legal } from "../Navigation";
import { VesterLogo } from "../../common/ScalableVectorGraphics";

import {
  LinksColumn,
  SocialsColumn
} from "./Columns";

import { Copyright } from "./Copyright";


interface FooterProps {};


export const Footer: React.FC<FooterProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      certificates: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "certificate"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              width
              height
              alt
            }
          }
        }
      }
    }
  `);

  return (
    <Box
      as="footer"
      top={ 0 }
      left={ 0 }
      w="100%"
      bg="background.linen"
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px',
          '2xl': '1536px'
        }}
        w="100%"
        padding="0 1rem"
      >
        <Grid
          templateColumns={{
            base: '1fr',
            lg: '2fr 3fr 1fr',
            xl: '3fr 3fr 1fr',
            '2xl': '4fr 3fr 1fr'
          }}
          gap={ 8 }
          padding="35px 0 72px"
        >
          <VStack
            spacing={ 6 }
            alignItems={{
              base: 'center',
              lg: 'flex-start'
            }}
          >
            <Link
              as={ GatsbyLink }
              to="/"
              w={{
                base: '224px',
                lg: '135px'
              }}
              h="auto"
            >
              <VesterLogo
                w="100%"
                h="100%"
                color="primary.900"
                transition="all 0.2s ease-in-out"
                _hover={{
                  opacity: '0.6'
                }}
              />
            </Link>
            <Text
              w="238px"
              color="accent.900"
              textAlign={{
                base: 'center',
                lg: 'left'
              }}
            >
              Servicing San Diego County for Over 20 Years.
            </Text>
            <Wrap
              justify={{
                base: 'center',
                lg: 'flex-start'
              }}
              spacing={ 10 }
            >
              { data.certificates.edges.map( ( node: any, _index: number ) => (
                <WrapItem
                  key={ _index }
                >
                  <Image
                    as={ GatsbyImage }
                    image={ node.node.frontmatter.image.childImageSharp.gatsbyImageData }
                    objectFit="contain"
                    w={ node.node.frontmatter.width }
                    h={ node.node.frontmatter.height }
                  />
                </WrapItem>
              ))}
            </Wrap>
          </VStack>
          <Grid
            templateColumns={{
              base: '1fr 1fr',
              lg: '1fr 1fr 1fr'
            }}
            gap={ 8 }
          >
            <LinksColumn
              heading="COMPANY"
              navList={[
                {
                  label: 'Home',
                  path: '/'
                },
                {
                  label: 'About',
                  path: '/about'
                },
                {
                  label: 'Services',
                  path: '/services'
                },
                {
                  label: 'Blog',
                  path: '/blog'
                }
              ]}
            />
            <LinksColumn
              heading="PAGES"
              navList={[
                {
                  label: 'Pest Library',
                  path: '/pest-library'
                },
                {
                  label: 'Promotions',
                  path: '/promotions'
                }
              ]}
            />
            <LinksColumn
              heading="SERVICES"
              navList={[
                {
                  label: 'Residential',
                  path: '/services/residential'
                },
                {
                  label: 'Commercial',
                  path: '/services/commercial'
                },
                {
                  label: 'Industrial',
                  path: '/services/industrial'
                }
              ]}
            />
          </Grid>
          <SocialsColumn
            heading="SOCIALS"
          />
        </Grid>
        <Divider />
        <Flex
          flexDirection={{
            base: 'column-reverse',
            lg: 'row'
          }}
          justifyContent={{
            lg: 'space-between'
          }}
          alignItems="center"
          gap={{
            base: 4
          }}
          padding="22px 0 31px"
        >
          <Copyright>
            © 2022 Vester Pest Control. All Rights Reserved. Developed by Explore Agency™
          </Copyright>
          <Legal />
        </Flex>
      </Container>
    </Box>
  )
}
