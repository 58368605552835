import * as React from "react";
import {
  Container,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { MediaImage } from "../../common/Media";
import { VesterIcon } from "../../common/ScalableVectorGraphics";


interface Props {
  heading: string
  author: string
  postDate: string
  image: IGatsbyImageData
  alt: string
};


export const BlogPostHero: React.FC<Props> = ( props ) => {
  return (
    <Container
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '776px'
      }}
      w="100%"
      padding={{
        base: '120px 1rem 64px',
        lg: '257px 0 84px'
      }}
    >
      <Stack
        direction="column"
        spacing={ 6 }
      >
        <Heading
          as="h1"
          color="accent.900"
          fontSize="48px"
          fontWeight={ 400 }
          lineHeight="54px"
        >
          { props.heading }
        </Heading>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={ 4 }
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            w="46px"
            h="46px"
            background="background.cloudburst"
            borderRadius="50%"
          >
            <VesterIcon
              w="18px"
              h="26px"
              color="primary.900"
            />
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            h="40px"
          >
            <Text
              fontSize="16px"
              lineHeight="21px"
            >
              { props.author }
            </Text>
            <Text
              fontSize="12px"
              lineHeight="16px"
            >
              { props.postDate }
            </Text>
          </Flex>
        </Stack>
        <MediaImage
          image={ props.image }
          alt={ props.alt }
          containerWidth={{
            base: '100%'
          }}
          containerHeight={{
            base: 'auto',
            lg: '456px'
          }}
          borderRadius="14px"
        />
      </Stack>
    </Container>
  )
}
