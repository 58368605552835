import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Heading,
  Link,
  Text
} from "@chakra-ui/react";


interface NavListItem {
  label: string
  path: string
}

type NavListType = NavListItem[];

interface LinksColumnProps {
  heading: string
  navList: NavListType
}


export const LinksColumn: React.FC<LinksColumnProps> = ( props ) => {
  return (
    <Box
      paddingTop={{
        lg: '14px'
      }}
    >
      <Heading
        as="h6"
        marginBottom="20px"
        color="primary.900"
        fontFamily="fonts.default"
        fontSize="16px"
      >
        { props.heading }
      </Heading>
      { props.navList.map(( item ) => {
        return (
          <Text
            my="15px"
          >
            <Link
              as={ GatsbyLink }
              to={ item.path }
              color="accent.900"
              _hover={{
                opacity: '0.6',
                textDecoration: 'none'
              }}
            >
              { item.label }
            </Link>
          </Text>
        )
      })}
    </Box>
  )
}
