import * as React from "react";
import { PhoneIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  isOpen: boolean
  onClose: any
};


export const CallNowModal: React.FC<Props> = ( props ) => {
  return (
    <Modal
      isOpen={ props.isOpen }
      onClose={ props.onClose }
      motionPreset="slideInBottom"
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
      >
        <ModalCloseButton />
        <Flex
          position="absolute"
          top="0"
          left="50%"
          justifyContent="center"
          alignItems="center"
          w="85px"
          h="85px"
          background="primary.900"
          borderRadius="50%"
          transform="translate(-50%, -50%)"
        >
          <PhoneIcon
            w="auto"
            h="34px"
            color="white"
          />
        </Flex>
        <VStack
          spacing={ 4 }
          padding="85px 0 50px"
          textAlign="center"
        >
          <Heading
            as="h4"
            color="accent.900"
            fontSize="28px"
            fontWeight={ 400 }
            lineHeight="33px"
          >
            Call Us Today
          </Heading>
          <Text
            color="accent.900"
            w="320px"
            fontSize="16px"
            fontWeight={ 400 }
            lineHeight="21px"
          >
            Got a Question? Vester Pest Constrol team is here to help you. 
          </Text>
          <Heading
            as="h3"
            color="primary.900"
            fontSize="48px"
            fontWeight={ 400 }
            lineHeight="54px"
          >
            +1 (858) 793-3471
          </Heading>
        </VStack>
      </ModalContent>
    </Modal>
  )
}
