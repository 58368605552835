import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const BurgerIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 17 4" { ...props }>
      <path
        fill="currentColor"
        d="M3 2C3 2.82843 2.32843 3.5 1.5 3.5C0.671573 3.5 0 2.82843 0 2C0 1.17157 0.671573 0.5 1.5 0.5C2.32843 0.5 3 1.17157 3 2Z"
      />
      <path
        fill="currentColor"
        d="M9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2Z"
      />
      <path
        fill="currentColor"
        d="M16.5 2C16.5 2.82843 15.8284 3.5 15 3.5C14.1716 3.5 13.5 2.82843 13.5 2C13.5 1.17157 14.1716 0.5 15 0.5C15.8284 0.5 16.5 1.17157 16.5 2Z"
      />
    </Icon>
  )
}
