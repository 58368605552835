import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

import { SectionContent } from "../../common/Section";

import { ContactForm } from "../Form";
import { Socials } from "../Navigation";


interface Props {
  heading?: string

  description?: string[]
};


export const ContactHero: React.FC<Props> = ( props ) => {
  return (
    <VStack
      align="flex-start"
      spacing={ 8 }
      w="100%"
      padding={{
        base: '120px 0 48px',
        lg: '226px 48px 76px'
      }}
    >
      <SectionContent
        justifyContent="flex-start"
        alignItems={{
          base: 'center',
          lg: 'flex-start'
        }}
        componentWidth="100%"
        componentHeight="auto"
        textAlign={{
          base: 'center',
          lg: 'left'
        }}
        heading={ props.heading }
        description={ props.description }
      />
      <Stack
        direction={{
          base: 'column-reverse',
          lg: 'row'
        }}
        justify="flex-start"
        align="flex-start"
        spacing={{
          base: 24,
          lg: 0
        }}
        w="100%"
      >
        <ContactForm
          flex="1 1 0%"
        />
        <VStack
          flex="1 1 0%"
          justify="flex-start"
          align="flex"
          spacing={ 8 }
          marginLeft={{
            base: '0',
            lg: '128px'
          }}
        >
          <Box
            w="220px"
          >
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Address
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              18029 Calle Ambiente, Suite 500, Rancho Santa Fe, CA
            </Text>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              92091
            </Text>
          </Box>
          <Box
            w="220px"
          >
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Email
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              info@vesterpest.com
            </Text>
          </Box>
          <Box
            w="220px"
          >
            <Heading
              as="h3"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Phone
            </Heading>
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              +1 (858) 793-3471
            </Text>
          </Box>
          <Box
            w="220px"
          >
            <Heading
              as="h3"
              marginBottom="8px"
              color="accent.900"
              fontSize="19px"
              fontWeight={ 700 }
              lineHeight="21px"
            >
              Socials
            </Heading>
            <Socials
              justify="flex-start"
              color="accent.900"
            />
          </Box>
          <Box
            w="220px"
          >
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
            >
              Servicing San Diego County for Over 20 Years.
            </Text>
          </Box>
          <Box>
            <Button
              as={ GatsbyLink }
              to="/estimate"
              variant="outline"
              color="primary.900"
              borderColor="primary.900"
              borderRadius="20px"
              _hover={{
                background: 'primary.900',
                color: 'white'
              }}
              _focus={{
                boxShadow: 'none !important'
              }}
            >
              Free Estimate
            </Button>
          </Box>
        </VStack>
      </Stack>
    </VStack>
  )
}
