import * as React from "react";
import { useEffect, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { PhoneIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link
} from "@chakra-ui/react";

import {
  VesterIcon,
  VesterLogo
} from "../../common/ScalableVectorGraphics";

import { CallNowModal } from "../Modal";
import { SiteNav } from "../Navigation";

import { Burger } from "./Burger";


interface Props {};


export const Header: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [prevScrollPos, setPrevScrollPos] = useState( 0 );
  const [headerTopVisible, setHeaderTopVisible] = useState( true );

  const handleScroll = (): void => {
    const currentScrollPos = window.pageYOffset;

    setHeaderTopVisible(currentScrollPos < 57);

    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, headerTopVisible, handleScroll])

  return (
    <Box
      as="header"
      position="fixed"
      top={ headerTopVisible ? 0 : {
        base: 0,
        lg: '-57px'
      }}
      left={ 0 }
      w="100%"
      bg="background.linen"
      transition="all 0.2s ease-in-out"
      zIndex={ 1000 }
    >
      <Container
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px',
          '2xl': '1536px'
        }}
        w="100%"
        padding={{
          base: '1rem',
          lg: '1rem 1rem 0'
        }}
      >
        <Flex
          display={{
            base: 'none',
            lg: 'flex'
          }}
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 1rem"
        >
          <Box>
            <Button
              as={ Link }
              href="mailto:info@vesterpest.com"
              variant="link"
              color="primary.900"
              _hover={{
                opacity: '0.6',
                textDecoration: 'none'
              }}
              _focus={{
                boxShadow: 'none !important'
              }}
            >
              info@vesterpest.com
            </Button>
          </Box>
          <Box>
            <Button
              as={ Link }
              href="#"
              variant="link"
              color="primary.900"
              _hover={{
                opacity: '0.6',
                textDecoration: 'none'
              }}
              _focus={{
                boxShadow: 'none !important'
              }}
            >
              +1 (858) 793-3471
            </Button>
          </Box>
        </Flex>
        <Divider
          display={{
            base: 'none',
            lg: 'block'
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          <Box
            display={{
              base: 'none',
              lg: 'block'
            }}
          >
            <Link
              as={ GatsbyLink }
              to="/"
              w="135px"
              h="auto"
            >
              <VesterLogo
                w="135px"
                h="auto"
                color="primary.900"
                transition="all 0.2s ease-in-out"
                _hover={{
                  opacity: '0.6'
                }}
              />
            </Link>
          </Box>
          <Box
            display={{
              base: 'none',
              lg: 'block'
            }}
          >
            <SiteNav
              headerTopVisible={ headerTopVisible }
            />
          </Box>
          <Box
            display={{
              base: 'none',
              lg: 'block'
            }}
          >
            <HStack
              spacing={{
                lg: 4,
                xl: 8
              }}
            >
              <Button
                as={ GatsbyLink }
                to="/estimate"
                variant="outline"
                color="primary.900"
                borderColor="primary.900"
                borderRadius="20px"
                _hover={{
                  background: 'primary.900',
                  color: 'white'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
              >
                Free Estimate
              </Button>
              <IconButton
                aria-label="Call us"
                icon={ <PhoneIcon /> }
                background="primary.900"
                color="white"
                border="1px"
                borderColor="primary.900"
                borderRadius="50%"
                _hover={{
                  background: 'transparent',
                  color: 'primary.900'
                }}
                _focus={{
                  boxShadow: 'none !important'
                }}
                onClick={ onOpen }
              />
              <CallNowModal
                isOpen={ isOpen }
                onClose={ onClose }
              />
            </HStack>
          </Box>
          <Box
            display={{
              lg: 'none'
            }}
          >
            <IconButton
              aria-label="Call us"
              as={ Link }
              href="tel:+18587933471"
              icon={ <PhoneIcon /> }
              background="transparent"
              color="primary.900"
              border="1px"
              borderColor="primary.900"
              borderRadius="50%"
              _focus={{
                boxShadow: 'none !important'
              }}
            />
          </Box>
          <Box
            display={{
              lg: 'none'
            }}
          >
            <Link
              as={ GatsbyLink }
              to="/"
              w="auto"
              h="49px"
            >
              <VesterIcon
                w="auto"
                h="49px"
                color="primary.900"
              />
            </Link>
          </Box>
          <Box
            display={{
              lg: 'none'
            }}
          >
            <Burger />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
