import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const EcoIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 28 32" { ...props }>
      <path
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.25"
        d="M14.7334 32C14.5515 31.8317 14.3491 31.6837 14.1916 31.4939C13.5416 30.7157 12.9044 29.9268 12.2646 29.1398C11.9272 28.7241 11.9611 28.4255 12.3825 28.0896C13.2521 27.3962 14.1222 26.7037 14.9927 26.012C15.213 25.8367 15.4557 25.8 15.7048 25.9354C15.954 26.0708 16.0647 26.2853 15.9981 26.5428C15.9489 26.7111 15.8518 26.862 15.7183 26.9774C15.2937 27.3412 14.848 27.6809 14.4247 28.1035C14.7955 28.0744 15.1714 28.0738 15.5364 28.0118C20.5859 27.1469 23.939 24.3259 25.3408 19.4393C26.8682 14.1149 24.012 8.26605 18.8985 5.98913C18.7704 5.93345 18.6423 5.88411 18.5206 5.82337C18.1691 5.65192 18.0372 5.3356 18.1819 5.01927C18.3266 4.70294 18.65 4.57641 19.0112 4.72256C19.9976 5.11814 20.9254 5.64357 21.7694 6.28458C24.6364 8.46596 26.3379 11.3369 26.8496 14.8697C27.7891 21.3537 23.289 27.6727 16.7826 29.0177C15.8931 29.2018 14.9793 29.2708 14.0533 29.3973L15.1086 30.6993C15.1951 30.8056 15.2886 30.9081 15.3648 31.0219C15.6152 31.3977 15.489 31.7217 14.9902 32.0013L14.7334 32Z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.25"
        d="M18.0634 10.5403C17.1899 11.0945 16.2543 11.3874 15.2816 11.5753C14.6251 11.7019 13.9662 11.817 13.3085 11.9378C11.3393 12.2991 10.3319 13.5948 9.8811 15.4086C9.61022 16.51 9.7383 17.5805 10.2397 18.5997C10.2852 18.692 10.3383 18.78 10.3896 18.8724C10.9019 18.215 11.386 17.5634 11.9048 16.9421C12.8608 15.8111 13.9226 14.7714 15.076 13.8371C15.286 13.6644 15.5102 13.5644 15.7804 13.6511C16.2492 13.801 16.3222 14.366 15.9284 14.7171C15.3289 15.2529 14.7116 15.7711 14.1397 16.336C12.6438 17.8184 11.3418 19.4468 10.4299 21.353C10.0002 22.2495 9.68322 23.1801 9.54874 24.1683C9.48982 24.6004 9.18628 24.8528 8.82381 24.7934C8.46135 24.7339 8.27948 24.4283 8.33647 24.0076C8.51066 22.7113 8.95573 21.5036 9.56603 20.3521C9.67105 20.1541 9.68258 20.0156 9.55258 19.8163C7.98105 17.4046 8.28844 14.5798 10.0214 12.4174C10.8289 11.4089 11.9746 10.9705 13.2118 10.7269C14.0552 10.5612 14.9018 10.4106 15.7401 10.2208C16.5757 10.0393 17.3463 9.6385 17.9706 9.06054C18.3977 8.66892 18.9043 8.80747 19.0464 9.36105C19.6535 11.736 19.9584 14.1344 19.4518 16.567C19.0528 18.4864 18.13 20.0617 16.2799 20.969C15.359 21.4207 14.3716 21.6048 13.3527 21.6377C12.9454 21.6503 12.6777 21.4061 12.6713 21.048C12.6649 20.7001 12.9018 20.4654 13.3002 20.4401C14.2864 20.3768 15.2444 20.2098 16.1006 19.6853C17.3558 18.9179 17.9622 17.7285 18.2479 16.3563C18.6193 14.5671 18.4822 12.7793 18.1614 10.9977C18.1351 10.8579 18.0993 10.7181 18.0634 10.5403Z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.25"
        d="M13.9905 3.66218C13.5877 3.16808 13.1856 2.68094 12.7917 2.18684C12.6936 2.0777 12.6196 1.94947 12.5746 1.81041C12.5361 1.68244 12.5454 1.54506 12.6007 1.42326C12.6561 1.30147 12.7539 1.20335 12.8763 1.14676C13.1465 1.00378 13.4142 1.04869 13.6005 1.27329C14.366 2.19105 15.1208 3.11599 15.865 4.0481C16.0834 4.32204 16.0424 4.63963 15.7651 4.86486C14.8519 5.6059 13.9316 6.33831 13.0043 7.06206C12.9426 7.11256 12.8712 7.1504 12.7945 7.17338C12.7177 7.19635 12.6371 7.204 12.5574 7.19587C12.4776 7.18774 12.4003 7.16401 12.3299 7.12604C12.2595 7.08808 12.1975 7.03665 12.1475 6.97475C11.933 6.7103 11.9599 6.36931 12.241 6.13206C12.6297 5.80435 13.0332 5.49371 13.4296 5.17549C13.4936 5.12551 13.5506 5.07173 13.6114 5.01986L13.5884 4.93571C13.0677 4.99202 12.5388 5.01037 12.0277 5.11222C7.67818 5.97959 4.62158 8.42669 3.02507 12.5263C0.829163 18.1658 3.58798 24.6283 9.17286 27.0773C9.25035 27.1115 9.32783 27.145 9.4034 27.1842C9.74729 27.3626 9.87601 27.684 9.73 27.9978C9.58399 28.3116 9.24522 28.4242 8.8898 28.2743C7.6322 27.7504 6.46955 27.0272 5.44769 26.1334C2.97448 23.9609 1.54128 21.2411 1.1468 17.9994C0.348867 11.39 4.93153 5.21977 11.559 3.97218C12.3492 3.82351 13.1574 3.7672 13.9905 3.66218Z"
      />
    </Icon>
  )
}
