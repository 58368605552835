import * as React from "react";
import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { useDisclosure, useOutsideClick } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  VStack
} from "@chakra-ui/react";
import { Calendar } from "@uselessdev/datepicker";

import { CalendarDate } from "@uselessdev/datepicker";

import {
  MessageFailIcon,
  MessageSentIcon
} from "../../common/ScalableVectorGraphics";


export const EstimateForm: React.FC = () => {
  const twilioMailFunctionURL = 'https://crimson-piranha-5388.twil.io/estimate';

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const popoverRef = useRef( null );

  const [lotSize, setLotSize] = useState( 2000 );
  const [issue, setIssue] = useState( '' );
  const [estimatePrice, setEstimatePrice] = useState( 0 );
  const [contactDate, setContactDate] = useState<CalendarDate>( new Date() );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectDate = ( contactDate: CalendarDate ) => {
    setContactDate( contactDate );

    onClose();
  };

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
    enabled: isOpen
  });

  const initialValues = {
    njhsdfjk: '', // Name
    ahbsjdfj: '', // Address
    phjhsdfh: '', // Phone
    zjbsdfjb: '', // Zipcode
    lbuebfub: 2000, // Lot sqft
    iuwbneub: '', // Issue (Pest)
    ejihsdfg: '', // Email
    djkhsdbf: new Date(), // Call Date
    tjbsdfjj: '', // Call Time
  };

  const validationSchema = Yup.object({
    njhsdfjk: Yup.string()
      .required('Required'),
    ahbsjdfj: Yup.string()
      .required('Required'),
    phjhsdfh: Yup.string()
      .required('Required'),
    zjbsdfjb: Yup.string()
      .required('Required')
      .test(
        'len',
        'Invalid (Eg. 91911)',
        val => val && val.length === 5
      ),
    lbuebfub: Yup.number()
      .required('Required'),
    iuwbneub: Yup.string()
      .required('Required'),
    ejihsdfg: Yup.string()
      .required('Required')
      .email('Invalid email address.'),
    djkhsdbf: Yup.date()
      .required('Required'),
    tjbsdfjj: Yup.string()
      .required('Required'),
  });

  const onSubmit = async (e: any, { setStatus }: any) => {
    const {
      njhsdfjk,
      ahbsjdfj,
      phjhsdfh,
      zjbsdfjb,
      lbuebfub,
      iuwbneub,
      ejihsdfg,
      djkhsdbf,
      tjbsdfjj
    } = e;

    const subject = `[VESTER Call Request] from ${ njhsdfjk }`;

    const date = format( contactDate, 'MMMM do, yyyy' );

    nextStep();

    const response = await fetch(twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        njhsdfjk,
        ahbsjdfj,
        phjhsdfh,
        zjbsdfjb,
        lbuebfub,
        iuwbneub,
        ejihsdfg,
        date,
        tjbsdfjj,
        subject
      }),
    });

    if (response.status === 200) {
      setStatus({
        success: true
      });
    } else {
      setStatus({
        success: false
      });
    }
  };

  const issueOptions = [
    { key: 'Ants', value: 'Ants' },
    { key: 'Bed Bugs', value: 'Bed Bugs' },
    { key: 'Cockroaches', value: 'Cockroaches' },
    { key: 'Flies', value: 'Flies' },
    { key: 'Mosquitoes', value: 'Mosquitoes' },
    { key: 'Rodents', value: 'Rodents' },
    { key: 'Spiders', value: 'Spiders' },
    { key: 'Stinging Pests', value: 'Stinging Pests' },
    { key: 'Other Pests', value: 'Other Pests' }
  ];

  const timeOptions = [
    { key: '8:00AM', value: '8:00AM' },
    { key: '9:00AM', value: '9:00AM' },
    { key: '10:00AM', value: '10:00AM' },
    { key: '11:00AM', value: '11:00AM' },
    { key: '12:00PM', value: '12:00PM' },
    { key: '1:00PM', value: '1:00PM' },
    { key: '2:00PM', value: '2:00PM' },
    { key: '3:00PM', value: '3:00PM' },
    { key: '4:00PM', value: '4:00PM' },
    { key: '5:00PM', value: '5:00PM' },
  ];

  const getIssuePrice = ( issueInput: string ) => {
    switch ( issueInput ) {
      case 'Ants':
        return 0;
      case 'Bed Bugs':
        return 0;
      case 'Cockroaches':
        return 0;
      case 'Flies':
        return 0;
      case 'Mosquitoes':
        return 0;
      case 'Rodents':
        return 0;
      case 'Spiders':
        return 0;
      case 'Stinging Pests':
        return 0;
      case 'Other Pests':
        return 0;
      default:
        return 0;
    }
  }

  const getLotSizePrice = ( lotSizeInput: number ) => {
    if ( lotSizeInput <= 1000 ) {
      return 195;
    } else if ( lotSizeInput <= 3000 ) {
      return 225;
    } else if ( lotSizeInput <= 4000 ) {
      return 300;
    } else if ( lotSizeInput <= 6000 ) {
      return 385;
    } else {
      return 475;
    }
  };

  const calcEstimate = () => {
    let lotSizePrice = getLotSizePrice( lotSize );
    let issuePrice = getIssuePrice( issue );

    setEstimatePrice( lotSizePrice + issuePrice );
    
    nextStep();
  };

  return (
    <Box
      maxW="472px"
      w={{
        base: '100%',
        sm: '472px'
      }}
      background="white"
      borderRadius="8px"
    >
      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { ( props ) => (
          <Form
            autoComplete="off"
          >
            <Box
              padding={{
                base: '24px',
                sm: '54px'
              }}
            >
              <Steps
                orientation="horizontal"
                colorScheme="primary"
                activeStep={ activeStep }
              >
                <Step>
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    w="100%"
                    h={{
                      base: '350px',
                      sm: '400px'
                    }}
                    padding={{
                      base: '0',
                      sm: '24px 0 0'
                    }}
                  >
                    <Heading
                      as="h3"
                      fontSize="28px"
                      fontWeight={ 400 }
                      lineHeight="33px"
                    >
                      Start Your Free Estimate
                    </Heading>
                    <VStack
                      spacing={ 8 }
                      w="100%"
                    >
                      <Field
                        name="njhsdfjk"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.njhsdfjk && form.touched.njhsdfjk }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="njhsdfjk"
                                margin="0"
                                color="accent.900"
                              >
                                Full name
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.njhsdfjk }
                              </FormErrorMessage>
                            </Flex>
                            <Input
                              id="njhsdfjk"
                              placeholder="Enter your name"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="ahbsjdfj"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.ahbsjdfj && form.touched.ahbsjdfj }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="ahbsjdfj"
                                margin="0"
                                color="accent.900"
                              >
                                Address
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.ahbsjdfj }
                              </FormErrorMessage>
                            </Flex>
                            <Input
                              id="ahbsjdfj"
                              placeholder="Enter your address"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="phjhsdfh"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.phjhsdfh && form.touched.phjhsdfh }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="phjhsdfh"
                                margin="0"
                                color="accent.900"
                              >
                                Phone Number
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.phjhsdfh }
                              </FormErrorMessage>
                            </Flex>
                            <Input
                              id="phjhsdfh"
                              placeholder="Enter your phone number"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            />
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                  </Flex>
                </Step>
                <Step>
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    w="100%"
                    h={{
                      base: '350px',
                      sm: '400px'
                    }}
                    padding={{
                      base: '0',
                      sm: '24px 0 0'
                    }}
                  >
                    <Heading
                      as="h3"
                      fontSize="28px"
                      fontWeight={ 400 }
                      lineHeight="33px"
                    >
                      Enter Your Details
                    </Heading>
                    <VStack
                      spacing={ 8 }
                      w="100%"
                    >
                      <Field
                        name="zjbsdfjb"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.zjbsdfjb && form.touched.zjbsdfjb }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="zjbsdfjb"
                                margin="0"
                                color="accent.900"
                              >
                                Zipcode
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.zjbsdfjb }
                              </FormErrorMessage>
                            </Flex>
                            <Input
                              id="zjbsdfjb"
                              placeholder="Enter your zipcode"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="lbuebfub"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.lbuebfub && form.touched.lbuebfub }
                            onChange={ ( event ) => {
                              const target = event.target as HTMLInputElement;
                              setLotSize( parseInt( target.value ) );
                            }}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="lbuebfub"
                                margin="0"
                                color="accent.900"
                              >
                                Lot Size (sqft.)
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.lbuebfub }
                              </FormErrorMessage>
                            </Flex>
                            <InputGroup>
                              <Input
                                id="lbuebfub"
                                placeholder="Enter your lot size"
                                variant="filled"
                                focusBorderColor="primary.900"
                                autoComplete="off"
                                { ...field }
                              />
                              <InputRightAddon
                                children="sqft."
                                border="0"
                              />
                            </InputGroup>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="iuwbneub"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.iuwbneub && form.touched.iuwbneub }
                            onChange={ ( event ) => {
                              const target = event.target as HTMLInputElement;
                              setIssue( target.value );
                            }}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="iuwbneub"
                                margin="0"
                                color="accent.900"
                              >
                                Issue
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.iuwbneub }
                              </FormErrorMessage>
                            </Flex>
                            <Select
                              id="iuwbneub"
                              placeholder="Select pest issue"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            >
                              { issueOptions.map(( issue, _index ) => (
                                <option
                                  key={ issue.key }
                                  value={ issue.value }
                                >
                                  { issue.key }
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                  </Flex>
                </Step>
                <Step>
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    w="100%"
                    h="400px"
                    padding={{
                      base: '0',
                      sm: '24px 0 0'
                    }}
                  >
                    <Heading
                      as="h3"
                      fontSize="28px"
                      fontWeight={ 400 }
                      lineHeight="33px"
                    >
                      Your Project Estimate:&nbsp;
                      <Heading
                        as="span"
                        color="primary.900"
                        fontSize="28px"
                        fontWeight={ 400 }
                        lineHeight="33px"
                      >
                        ${ estimatePrice }*
                      </Heading>
                    </Heading>
                    <Text
                      fontSize="9px"
                      fontWeight={ 400 }
                      lineHeight="12px"
                    >
                      * An estimate provides approximate costs for a project, pricing may change following our inspection.
                    </Text>
                    <VStack
                      spacing={ 8 }
                      w="100%"
                    >
                      <Field
                        name="ejihsdfg"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.ejihsdfg && form.touched.ejihsdfg }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="ejihsdfg"
                                margin="0"
                                color="accent.900"
                              >
                                Email Address
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.ejihsdfg }
                              </FormErrorMessage>
                            </Flex>
                            <Input
                              id="ejihsdfg"
                              placeholder="Enter your email"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="djkhsdbf"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.djkhsdbf && form.touched.djkhsdbf }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="djkhsdbf"
                                margin="0"
                                color="accent.900"
                              >
                                Select Date
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.djkhsdbf }
                              </FormErrorMessage>
                            </Flex>
                            <Popover
                              isOpen={ isOpen }
                              onClose={ onClose }
                              isLazy
                            >
                              <PopoverTrigger>
                                <Input
                                  id="djkhsdbf"
                                  placeholder="Select date"
                                  variant="filled"
                                  focusBorderColor="primary.900"
                                  value={ format( contactDate, 'MMMM do, yyyy' ) }
                                  onClick={ onOpen }
                                  onChange={ form.handleChange }
                                />
                              </PopoverTrigger>

                              <PopoverContent
                                w="min-content"
                                padding={ 0 }
                                border="none"
                                outline="none"
                                ref={ popoverRef }
                                _focus={{
                                  boxShadow: 'none'
                                }}
                              >
                                <PopoverBody>
                                  <Calendar
                                    value={{ start: contactDate }}
                                    singleMonth
                                    singleDateSelection
                                    disablePastDates
                                    disableWeekends
                                    onSelectDate={ handleSelectDate }
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="tjbsdfjj"
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={ form.errors.tjbsdfjj && form.touched.tjbsdfjj }
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              margin="0 0 8px"
                            >
                              <FormLabel
                                htmlFor="tjbsdfjj"
                                margin="0"
                                color="accent.900"
                              >
                                Select Time (PST)
                              </FormLabel>
                              <FormErrorMessage
                                margin="0"
                              >
                                { form.errors.tjbsdfjj }
                              </FormErrorMessage>
                            </Flex>
                            <Select
                              id="tjbsdfjj"
                              placeholder="Select time"
                              variant="filled"
                              focusBorderColor="primary.900"
                              autoComplete="off"
                              { ...field }
                            >
                              { timeOptions.map(( time, _index ) => (
                                <option
                                  key={ time.key }
                                  value={ time.value }
                                >
                                  { time.key }
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                  </Flex>
                </Step>
              </Steps>
            </Box>
            { activeStep === 3 ? (
              <>
                { props.status &&
                  <Flex
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    h="460px"
                  >
                    <VStack
                      spacing={ 8 }
                      align="center"
                    >
                      { props.status.success ? (
                          <MessageSentIcon
                            w="112px"
                            h="105px"
                            color="#1DC556"
                          />
                        ) : (
                          <MessageFailIcon
                            w="104px"
                            h="104px"
                            color="#FF1010"
                          />
                        )
                      }
                      <Heading
                        as="h3"
                        fontSize="28px"
                        fontWeight={ 400 }
                        lineHeight="33px"
                      >
                        { props.status.success ? (
                            <>
                              Your Request Has Been Sent!
                            </>
                          ) : (
                            <>
                              Something Went Wrong.
                            </>
                          )
                        }
                      </Heading>
                      <Text
                        fontSize="12px"
                        fontWeight={ 400 }
                        lineHeight="16px"
                      >
                        { props.status.success ? (
                            <>
                              One of our experts will get in touch with you.  
                            </>
                          ) : (
                            <>
                              Please contact us directly at&nbsp;
                              <Link
                                href="mailto:info@vesterpest.com"
                                textDecoration="underline"
                                transition="all 0.2s ease-in-out"
                                _hover={{
                                  opacity: '0.6'
                                }}
                              >
                                info@vesterpest.com
                              </Link>
                              .
                            </>
                          )
                        }
                      </Text>
                    </VStack>
                    <Text
                      position="absolute"
                      bottom="24px"
                      left={ 0 }
                      w="100%"
                      textAlign="center"
                    >
                      &copy; { new Date().getFullYear() } Vester Pest Control. All Rights Reserved.
                    </Text>
                  </Flex>
                }
              </>
            ) : (
              <Flex
                justifyContent="space-between"
                w="100%"
              >
                <Button
                  disabled={ activeStep === 0 }
                  flex="1 1 0%"
                  h="60px"
                  borderRadius="0px 0px 0 8px"
                  opacity="0.6"
                  onClick={ prevStep }
                  _hover={{
                    opacity: '0.4',
                  }}
                  _focus={{
                    boxShadow: 'none !important'
                  }}
                >
                  Previous
                </Button>
                <Button
                  disabled={ activeStep === 2 && !props.isValid }
                  type={ activeStep === 2 ? 'submit' : undefined }
                  flex="1 1 0%"
                  h="60px"
                  background="primary.900"
                  borderRadius="0px 0px 8px 0"
                  color="white"
                  onClick={ activeStep === 1 ? calcEstimate : activeStep === 2 ? props.submitForm : nextStep }
                  isLoading={ activeStep === 2 ? props.isSubmitting : undefined }
                  _hover={{
                    opacity: '0.6',
                  }}
                  _focus={{
                    boxShadow: 'none !important'
                  }}
                >
                  { activeStep === 2 ? 'Request A Call' : 'Next' }
                </Button>
              </Flex>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}
