import * as React from "react";
import {
  Text
} from "@chakra-ui/react";


interface FooterProps {
  children: React.ReactNode
};


export const Copyright: React.FC<FooterProps> = ( props ) => {
  return (
    <Text
      color="accent.900"
      fontSize="12px"
      lineHeight="16px"
      textAlign={{
        base: 'center',
        lg: 'left'
      }}
    >
      { props.children }
    </Text>
  )
}
