import * as React from "react";
import {
  HStack,
  IconButton,
  Link
} from "@chakra-ui/react";

import {
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  YelpIcon
} from "../../common/ScalableVectorGraphics";


interface Props {
  justify?: "flex-start" | "space-between"
  color: string
};


export const Socials: React.FC<Props> = ( props ) => {
  return (
    <HStack
      justify={{
        base: 'flex-start',
        lg: props.justify
      }}
      align="center"
      spacing={ props.justify === "flex-start" ? 4 : undefined }
      w={ props.justify === "space-between" ? '100%' : 'auto' }
    >
      <IconButton
        aria-label="Facebook"
        as={ Link }
        href="https://www.facebook.com/VestPestControl"
        isExternal
        icon={ <FacebookIcon /> }
        minW="32px"
        w={{
          base: '32px',
          lg: '40px'
        }}
        h={{
          base: '32px',
          lg: '40px'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor={ props.color }
        color={ props.color }
        transition="all 0.3s ease-in-out"
        _hover={{
          bg: props.color,
          color: "white"
        }}
      />
      <IconButton
        aria-label="Instagram"
        as={ Link }
        href="https://www.instagram.com/vesterpestcontrol/"
        isExternal
        icon={ <InstagramIcon /> }
        minW="32px"
        w={{
          base: '32px',
          lg: '40px'
        }}
        h={{
          base: '32px',
          lg: '40px'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor={ props.color }
        color={ props.color }
        transition="all 0.3s ease-in-out"
        _hover={{
          bg: props.color,
          color: "white"
        }}
      />
      <IconButton
        aria-label="Yelp"
        as={ Link }
        href="https://www.yelp.com/biz/vester-pest-control-solana-beach"
        isExternal
        icon={ <YelpIcon /> }
        minW="32px"
        w={{
          base: '32px',
          lg: '40px'
        }}
        h={{
          base: '32px',
          lg: '40px'
        }}
        bg="transparent"
        border="1px"
        borderRadius="50%"
        borderColor={ props.color }
        color={ props.color }
        transition="all 0.3s ease-in-out"
        _hover={{
          bg: props.color,
          color: "white"
        }}
      />
      { /*
        <IconButton
          aria-label="Google"
          as={ Link }
          href="https://goo.gl/maps/BgcDSk7Mqqhw2P1w6"
          isExternal
          icon={ <GoogleIcon /> }
          minW="32px"
          w={{
            base: '32px',
            lg: '40px'
          }}
          h={{
            base: '32px',
            lg: '40px'
          }}
          bg="transparent"
          border="1px"
          borderRadius="50%"
          borderColor={ props.color }
          color={ props.color }
          transition="all 0.3s ease-in-out"
          _hover={{
            bg: props.color,
            color: "white"
          }}
        />
        */}
    </HStack>
  )
}
