import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const QuotationsIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 28 23" { ...props }>
      <path
        fill="currentColor" 
        d="M10.8241 0C11.1265 0.176246 11.4506 0.506705 11.7963 0.991378C12.1852 1.47605 12.3796 2.13697 12.3796 2.97414C10.0895 3.63506 8.51235 4.42816 7.64815 5.35345C6.82716 6.23467 6.41667 7.18199 6.41667 8.1954C6.41667 9.38506 6.71914 10.3103 7.32407 10.9713C7.92901 11.5881 8.62037 12.1389 9.39815 12.6236C10.2191 13.1082 10.9321 13.7031 11.537 14.408C12.142 15.113 12.4444 16.1485 12.4444 17.5144C12.4444 19.1006 11.8611 20.4224 10.6944 21.4799C9.57099 22.4933 8.2963 23 6.87037 23C4.75309 23 3.0679 22.2069 1.81481 20.6207C0.604938 18.9904 0 16.7653 0 13.9454C0 11.6542 0.453704 9.53927 1.36111 7.60057C2.26852 5.66188 3.54321 4.03161 5.18519 2.70977C6.82716 1.34387 8.70679 0.440613 10.8241 0ZM26.3796 0C26.6821 0.176246 27.0062 0.506705 27.3519 0.991378C27.7407 1.47605 27.9352 2.13697 27.9352 2.97414C25.6451 3.63506 24.0679 4.42816 23.2037 5.35345C22.3827 6.23467 21.9722 7.18199 21.9722 8.1954C21.9722 9.38506 22.2747 10.3103 22.8796 10.9713C23.4846 11.5881 24.1759 12.1389 24.9537 12.6236C25.7747 13.1082 26.4877 13.7031 27.0926 14.408C27.6975 15.113 28 16.1485 28 17.5144C28 19.1006 27.4167 20.4224 26.25 21.4799C25.1265 22.4933 23.8519 23 22.4259 23C20.3086 23 18.6235 22.2069 17.3704 20.6207C16.1605 18.9904 15.5556 16.7653 15.5556 13.9454C15.5556 11.6542 16.0093 9.53927 16.9167 7.60057C17.8241 5.66188 19.0988 4.03161 20.7407 2.70977C22.3827 1.34387 24.2623 0.440613 26.3796 0Z"
      />
    </Icon>
  )
}
