import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const SatisfactionIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 37 34" { ...props }>
      <path
        fill="currentColor"
        d="M18.7212 28.7499C21.2037 28.7499 23.5847 27.7637 25.3401 26.0083C27.0955 24.2529 28.0816 21.8719 28.0816 19.3895C28.0816 16.907 27.0955 14.526 25.3401 12.7706C23.5847 11.0152 21.2037 10.0291 18.7212 10.0291C16.2388 10.0291 13.8578 11.0152 12.1024 12.7706C10.347 14.526 9.36084 16.907 9.36084 19.3895C9.36084 21.8719 10.347 24.2529 12.1024 26.0083C13.8578 27.7637 16.2388 28.7499 18.7212 28.7499ZM18.7212 11.3663C20.8491 11.3663 22.8899 12.2115 24.3945 13.7162C25.8991 15.2209 26.7444 17.2615 26.7444 19.3895C26.7444 21.5174 25.8992 23.5581 24.3945 25.0627C22.8898 26.5673 20.8491 27.4127 18.7212 27.4127C16.5933 27.4127 14.5526 26.5674 13.048 25.0627C11.5434 23.558 10.698 21.5174 10.698 19.3895C10.698 17.2615 11.5433 15.2208 13.048 13.7162C14.5527 12.2116 16.5933 11.3663 18.7212 11.3663Z"
      />
      <path
        fill="currentColor"
        d="M22.4456 18.1929C22.7876 18.1929 23.116 18.0571 23.358 17.815C23.6001 17.573 23.7359 17.2449 23.7359 16.9026C23.7359 16.5605 23.6001 16.2322 23.358 15.9902C23.1159 15.7481 22.7876 15.6123 22.4456 15.6123C22.1032 15.6123 21.7752 15.7481 21.5332 15.9902C21.2911 16.2323 21.1553 16.5606 21.1553 16.9026C21.1553 17.245 21.2911 17.573 21.5332 17.815C21.7752 18.0571 22.1032 18.1929 22.4456 18.1929ZM22.4456 16.8557C22.4712 16.8557 22.4924 16.8769 22.4924 16.9026C22.4924 16.956 22.3987 16.956 22.3987 16.9026C22.3987 16.8903 22.4038 16.8784 22.4124 16.8694C22.4211 16.8608 22.433 16.8557 22.4456 16.8557H22.4456Z"
      />
      <path
        fill="currentColor"
        d="M14.9969 18.1929C15.3392 18.1929 15.6672 18.0571 15.9093 17.815C16.1513 17.573 16.2872 17.2449 16.2872 16.9026C16.2872 16.5605 16.1514 16.2322 15.9093 15.9902C15.6672 15.7481 15.3392 15.6123 14.9969 15.6123C14.6548 15.6123 14.3265 15.7481 14.0844 15.9902C13.8423 16.2323 13.7065 16.5606 13.7065 16.9026C13.7065 17.245 13.8423 17.573 14.0844 17.815C14.3265 18.0571 14.6548 18.1929 14.9969 18.1929ZM14.9969 16.8557C15.0228 16.8557 15.0437 16.8769 15.0437 16.9026C15.0437 16.956 14.95 16.956 14.95 16.9026C14.95 16.8903 14.9551 16.8784 14.9637 16.8694C14.9727 16.8608 14.9846 16.8557 14.9968 16.8557H14.9969Z"
      />
      <path
        fill="currentColor"
        d="M14.9967 19.9641C14.6275 19.9641 14.3281 20.2635 14.3281 20.6327C14.3281 22.2021 15.1654 23.6525 16.5246 24.4372C17.8836 25.2216 19.5581 25.2216 20.9171 24.4372C22.2764 23.6524 23.1136 22.2021 23.1136 20.6327C23.1136 20.2635 22.8142 19.9641 22.445 19.9641C22.0758 19.9641 21.7764 20.2635 21.7764 20.6327C21.7764 21.7246 21.1941 22.7331 20.2485 23.2791C19.3032 23.8247 18.1385 23.8247 17.1932 23.2791C16.2477 22.7331 15.6653 21.7246 15.6653 20.6327C15.6653 20.4554 15.5949 20.2856 15.4695 20.1599C15.3442 20.0346 15.174 19.9641 14.9967 19.9641Z"
      />
      <path
        fill="currentColor"
        d="M0.668929 20.0582H5.34913C5.52643 20.0582 5.69656 19.9877 5.82193 19.8624C5.94729 19.737 6.01773 19.5669 6.01773 19.3896C6.03116 16.0732 7.34087 12.8934 9.66729 10.5297C11.9936 8.16595 15.1522 6.80583 18.4684 6.73981C21.7845 6.67384 24.9947 7.90714 27.4131 10.1766C27.4728 10.4171 27.6605 10.6049 27.9011 10.6646C30.1546 13.0113 31.4166 16.1363 31.4247 19.39C31.4247 19.5673 31.4951 19.7374 31.6205 19.8628C31.7458 19.9882 31.916 20.0586 32.0933 20.0586H36.7735C36.9508 20.0586 37.1209 19.9882 37.2463 19.8628C37.3716 19.7374 37.4421 19.5673 37.4421 19.39C37.4397 16.1317 36.5869 12.9309 34.9682 10.103L37.9167 7.15455C38.176 6.89367 38.176 6.47251 37.9167 6.21193L31.8993 0.194534C31.6387 -0.0648446 31.2175 -0.0648446 30.9566 0.194534L28.0082 3.14295C24.1957 0.964914 19.739 0.196062 15.4172 0.970874C11.0954 1.74576 7.18382 4.01511 4.365 7.38195C1.54703 10.7488 0.00175746 14.9992 0 19.39C0 19.5673 0.0704422 19.7374 0.195804 19.8628C0.321166 19.9882 0.491303 20.0586 0.6686 20.0586L0.668929 20.0582ZM32.7617 18.721C32.6214 15.7959 31.5639 12.9886 29.7396 10.6978H33.7915C35.2045 13.1438 35.9984 15.8983 36.1047 18.721H32.7617ZM28.7501 4.28608L31.4245 1.61168L36.4993 6.68647L33.8249 9.36087H28.7501V4.28608ZM27.4129 4.33951V8.35142C24.8048 6.29098 21.5484 5.22688 18.2267 5.34929C14.9055 5.47197 11.7363 6.77306 9.28737 9.02002L6.92038 6.65302C9.63833 4.12396 13.0963 2.5346 16.7851 2.11785C20.4743 1.70147 24.1997 2.48021 27.4132 4.33974L27.4129 4.33951ZM5.97112 7.58906L8.34461 9.96255C6.13854 12.365 4.84252 15.4636 4.68076 18.7208H1.33776C1.49864 14.5749 3.14298 10.6243 5.97112 7.58845V7.58906Z"
      />
    </Icon>
  )
}
