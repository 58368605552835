import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const ArrowRightIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 6 8" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99645 3.32996C5.12143 3.45498 5.19164 3.62452 5.19164 3.8013C5.19164 3.97807 5.12143 4.14761 4.99645 4.27263L1.93445 7.33463C1.81026 7.45634 1.64305 7.52412 1.46917 7.52324C1.29528 7.52236 1.12877 7.4529 1.00581 7.32994C0.882852 7.20698 0.813387 7.04047 0.812508 6.86658C0.81163 6.6927 0.87941 6.52549 1.00112 6.4013L3.60112 3.8013L1.00112 1.2013C0.87941 1.07711 0.81163 0.909898 0.812508 0.736012C0.813387 0.562126 0.882852 0.395612 1.00581 0.272655C1.12877 0.149698 1.29528 0.0802324 1.46917 0.0793541C1.64305 0.0784759 1.81026 0.146256 1.93445 0.267965L4.99645 3.32996Z"
      />
    </Icon>
  )
}
