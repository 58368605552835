import * as React from "react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CertificateIcon,
  EcoIcon,
  MaintenanceIcon,
  MechanicalIcon,
  PropertyIcon,
  QuotationsIcon,
  SatisfactionIcon
} from "./Icons";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon
} from "./Social";


interface Props {
  icon: string
  w?: string
  h?: string
  color?: string
  transition?: string
  _hover?: object
};


export const IconFactory: React.FC<Props> = ( props ) => {
  switch ( props.icon ) {
    case 'arrow-left':
      return <ArrowLeftIcon { ...props } />;
    case 'arrow-right':
      return <ArrowRightIcon { ...props } />;
    case 'certificate':
      return <CertificateIcon { ...props } />;
    case 'eco':
      return <EcoIcon { ...props } />;
    case 'maintenance':
      return <MaintenanceIcon { ...props } />;
    case 'mechanical':
      return <MechanicalIcon { ...props } />;
    case 'property':
      return <PropertyIcon { ...props } />;
    case 'quotations':
      return <QuotationsIcon { ...props } />;
    case 'satisfaction':
      return <SatisfactionIcon { ...props } />;
    default:
      return null;
  }
}


export const SocialIconFactory: React.FC<Props> = ( props ) => {
  switch ( props.icon ) {
    case 'facebook':
      return <FacebookIcon { ...props } />;
    case 'instagram':
      return <InstagramIcon { ...props } />;
    case 'twitter':
      return <TwitterIcon { ...props } />;
    default:
      return null;
  }
}
