/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import {
  Box
} from "@chakra-ui/react";

import { Header } from "../Header";
import { Footer } from "../Footer";


interface LayoutProps {
  children: React.ReactNode
};


export const Layout: React.FC<LayoutProps> = ( props ) => {
  return (
    <>
      <Header />
      
      <Box
        as="main"
      >
        { props.children }
      </Box>

      <Footer />
    </>
  )
}
