import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Flex
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { MediaImage } from "../../common/Media";
import { SectionContent } from "../../common/Section";


interface Props {
  // Global props

  // Heading props
  heading?: string

  // Description props
  description?: string[]

  // Button component
  Button?: React.ReactNode

  // Image props
  image: IGatsbyImageData
  alt: string
};


export const ServiceHero: React.FC<Props> = ( props ) => {
  return (
    <Flex
      flexDirection={{
        base: 'column',
        lg: 'row'
      }}
      justifyContent={{
        lg: 'space-between'
      }}
      alignItems={{
        lg: 'center'
      }}
      w="100%"
      h={{
        lg: '800px',
        '2xl': '1000px'
      }}
      padding={{
        base: '120px 0 88px',
        lg: '0'
      }}
    >
      <SectionContent
        alignItems="left"
        componentWidth={{
          sm: '432px',
          xl: '544px',
          '2xl': '656px'
        }}
        padding={{
          base: '0 0 32px',
          lg: '0'
        }}
        textAlign="left"
        heading={ props.heading }
        description={ props.description }
        Button={
          <Box>
            <Button
              as={ GatsbyLink }
              to="/estimate"
              variant="solid"
              size="lg"
              colorScheme="primary"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
            >
              Request a Quote
            </Button>
          </Box>
        }
      />
      <MediaImage
        image={ props.image }
        alt={ props.alt }
        objectFit="cover"
        containerWidth={{
          base: '100%',
          lg: '492px',
          xl: '619px',
          '2xl': '746px'
        }}
        containerHeight={{
          base: 'auto',
          lg: '398px',
          xl: '500px',
          '2xl': '602px'
        }}
        borderRadius="16px"
      />
    </Flex>
  )
}
