import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Flex
} from "@chakra-ui/react";

import { VesterIcon } from "../../common/ScalableVectorGraphics";
import { SectionContent } from "../../common/Section";


interface Props {
  // Global props
  hasBackground?: boolean

  // Heading props
  heading?: string

  // Description props
  description?: string[]
};


export const ErrorHero: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      w="100%"
      h="100%"
      padding={{
        base: props.hasBackground ? '120px 0' : '120px 0 0',
        lg: '0'
      }}
    >
      { props.hasBackground &&
        <Flex
          position="absolute"
          top="50%"
          left="50%"
          justifyContent="center"
          alignItems="center"
          w={{
            base: '290px',
            sm: '350px',
            lg: '400px'
          }}
          h={{
            base: '290px',
            sm: '350px',
            lg: '400px'
          }}
          background="white"
          borderRadius="50%"
          transform="translate(-50%, -50%)"
        >
          <VesterIcon
            w="100%"
            h="100%"
            color="background.linen"
          />
        </Flex>
      }
      <SectionContent
        justifyContent="center"
        alignItems="center"
        componentWidth="100%"
        componentHeight={ props.hasBackground ?
          {
            lg: '800px'
          } :
          'auto'
        }
        padding={ props.hasBackground ?
          '0' :
          {
            lg: '226px 0 76px'
          }
        }
        textAlign="center"
        heading={ props.heading }
        description={ props.description }
        Button={
          <Box>
            <Button
              as={ GatsbyLink }
              to="/"
              variant="solid"
              size="lg"
              colorScheme="primary"
              border="1px"
              borderColor="primary.900"
              borderRadius="24px"
              _hover={{
                background: 'transparent',
                color: 'primary.900'
              }}
            >
              Back to Home Page
            </Button>
          </Box>
        }
      />
    </Box>
  )
}
