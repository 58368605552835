import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w: string
  h: string
  color: string
  transition: string
  _hover: object
}


export const VesterLogo: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 135 35" { ...props }>
      <path
        fill="currentColor"
        d="M24.6602 23.7695C24.6602 17.2031 29.4842 12.5183 35.5682 12.5183C41.5442 12.5183 46.1162 17.1263 46.1162 23.5007C46.1162 24.1535 46.0802 24.6911 46.0082 25.3055H30.0602C30.5282 27.8015 32.6162 29.8751 35.6762 29.8751C37.5122 29.8751 39.1682 29.1071 39.9242 27.8399H45.4682C44.7482 31.3727 41.0762 34.7135 35.5682 34.7135C29.0882 34.6751 24.6602 30.1823 24.6602 23.7695ZM35.4242 17.3567C32.9402 17.3567 30.7802 18.9311 30.1682 21.3887H40.6442C39.9602 18.8927 38.0162 17.3567 35.4242 17.3567Z"
      />
      <path
        fill="currentColor"
        d="M48.5532 26.954H54.2412C54.6372 28.9508 56.5092 30.2564 59.2092 30.2564C60.7572 30.2564 62.9892 29.834 62.9892 28.1828C62.9892 26.762 61.4412 26.6468 57.9852 25.9172C53.4492 24.9188 49.4532 23.7284 49.3452 19.1204C49.3092 14.1668 54.3132 12.4004 58.6332 12.4004C63.3852 12.4004 68.0292 14.474 68.6052 20.1188H62.9532C62.3772 18.0836 60.3612 17.162 58.1652 17.162C56.7972 17.162 54.8532 17.546 54.8532 19.082C54.8532 20.618 56.8332 20.81 60.0372 21.5012C64.6812 22.4996 68.6772 23.7284 68.6772 28.0292C68.6772 32.5988 64.2852 34.9028 59.1372 34.9028C53.8452 34.8644 49.1652 32.4452 48.5532 26.954Z"  
      />
      <path
        fill="currentColor"
        d="M75.0962 27.5713V18.3169H70.5962V12.9409H73.0802C74.6642 12.9409 75.5642 12.0193 75.5642 10.2529V7.83374H80.6762V12.9409H87.8762V18.3169H80.6762V26.4193C80.6762 28.4545 81.4682 29.2225 83.6282 29.2225C84.9962 29.2225 86.4002 29.1073 87.8762 28.8001V34.1377C86.3642 34.4449 84.2762 34.6753 82.8002 34.6753C77.4362 34.6753 75.0962 32.1025 75.0962 27.5713Z"
      />
      <path
        fill="currentColor"
        d="M90.54 23.7695C90.54 17.2031 95.364 12.5183 101.448 12.5183C107.424 12.5183 111.996 17.1263 111.996 23.5007C111.996 24.1535 111.96 24.6911 111.888 25.3055H95.94C96.408 27.8015 98.496 29.8751 101.556 29.8751C103.392 29.8751 105.048 29.1071 105.804 27.8399H111.348C110.628 31.3727 106.956 34.7135 101.448 34.7135C94.968 34.6751 90.54 30.1823 90.54 23.7695ZM101.34 17.3567C98.856 17.3567 96.696 18.9311 96.084 21.3887H106.56C105.876 18.8927 103.932 17.3567 101.34 17.3567Z"
      />
      <path
        fill="currentColor"
        d="M116.604 12.9407H121.68V15.3599C123.12 13.5551 125.244 12.5183 127.584 12.5183C131.868 12.5183 135 15.5519 135 20.6207V22.0799H129.456V21.2351C129.456 19.1615 128.124 18.0863 126.216 18.0863C123.948 18.0863 122.148 20.1215 122.148 22.7711V34.2527H116.604V12.9407Z"
      />
      <path
        fill="currentColor"
        d="M23.76 0L8.208 19.1232H15.192L11.844 29.0304H11.556L8.208 19.1232L6.12 12.9024H0V12.9408L7.812 34.2528H7.848H15.408H15.444L23.328 12.9024H18L23.76 0Z"
      />
    </Icon>
  )
}
