import * as React from "react";
import {
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  // VStack props
  flex?: object | string
  justifyContent?: object | string
  alignItems?: object | string
  componentWidth?: object | string
  componentHeight?: object | string
  padding?: object | string
  textAlign: object | "left" | "center" | "right"

  // Heading components/props
  heading?: string
  headingWidth?: object | string
  headingMargin?: object | string

  // Description components/props
  Description?: React.ReactNode
  description?: React.ReactNode[] | string[]
  descriptionWidth?: object | string

  // Button components/props
  Button?: React.ReactNode
};


export const SectionContent: React.FC<Props> = ( props ) => {
  return (
    <VStack
      position="relative"
      flex={ props.flex }
      spacing={ 8 }
      justifyContent={ props.justifyContent }
      alignItems={ props.alignItems }
      minW={ props.componentWidth }
      w={ props.componentWidth }
      h={ props.componentHeight }
      padding={ props.padding }
      textAlign={ props.textAlign }
      zIndex={ 20 }
    >
      <Heading
        as="h2"
        w={ props.headingWidth }
        margin={ props.headingMargin }
        color="accent.900"
        fontSize="48px"
        fontWeight={ 400 }
        lineHeight="54px"
      >
        { props.heading }
      </Heading>
      { props.description &&
        <VStack
          spacing={ 8 }
          alignItems={ props.alignItems }
          w={ props.descriptionWidth }
        >
          { props.description.map(( paragraph, _index ) => (
            <Text
              color="accent.900"
              fontSize="16px"
              fontWeight={ 400 }
              lineHeight="21px"
              textAlign={ props.textAlign }
            >
              { paragraph }
            </Text>
          ))}
        </VStack>
      }
      { props.Description &&
        <>
          { props.Description }
        </>
      }
      { props.Button &&
        <>
          { props.Button }
        </>
      }
    </VStack>
  )
}
