import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";


interface Props {
  flex?: object | string
};


export const ContactForm: React.FC<Props> = ( props ) => {
  const twilioMailFunctionURL = 'https://crimson-piranha-5388.twil.io/contact';

  const initialValues = {
    njhsdfjk: '', // Name
    phjhsdfh: '', // Phone
    ejihsdfg: '', // Email
    sdvvsdfe: '', // Subject
    mdknfken: '', // Message
    name: '', // Honeypot Name
    email: '', // Honeypot Email
    tcikjsdf: false // Terms
  };

  const validationSchema = Yup.object({
    njhsdfjk: Yup.string()
      .required('Required'),
    phjhsdfh: Yup.string()
      .required('Required'),
    ejihsdfg: Yup.string()
      .required('Required')
      .email('Invalid email address.'),
    sdvvsdfe: Yup.string(),
    mdknfken: Yup.string()
      .required('Required'),
    name: Yup.string(),
    email: Yup.string(),
    tcikjsdf: Yup.boolean()
      .required('Required')
      .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
  });

  const onSubmit = async (e: any, { resetForm, setStatus }: any) => {
    const {
      njhsdfjk,
      phjhsdfh,
      ejihsdfg,
      sdvvsdfe,
      mdknfken,
      name,
      email,
      tcikjsdf
    } = e;

    const subject = sdvvsdfe ? `[VESTER Contact Form] ${ sdvvsdfe }` : '[VESTER Contact Form]';

    const response = await fetch(twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        njhsdfjk,
        phjhsdfh,
        ejihsdfg,
        subject,
        mdknfken,
        name,
        email,
        tcikjsdf
      }),
    });

    resetForm();

    if (response.status === 200) {
      setStatus({
        success: true
      });
    } else {
      setStatus({
        success: false
      });
    }
  };

  return (
    <Box
      flex={ props.flex }
    >
      <Box
        w={{
          base: '100%',
          lg: '400px'
        }}
      >
        <Formik
          initialValues={ initialValues }
          validationSchema={ validationSchema }
          onSubmit={ onSubmit }
        >
          { ( formik ) => (
            <Form
              autoComplete="off"
            >
              <VStack
                align="flex-start"
                spacing={ 6 }
              >
                <Field
                  name="njhsdfjk"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.njhsdfjk && form.touched.njhsdfjk }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="njhsdfjk"
                          margin="0"
                          color="accent.900"
                        >
                          Full name
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.njhsdfjk }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="njhsdfjk"
                        placeholder="Enter your name"
                        variant="filled"
                        focusBorderColor="primary.900"
                        autoComplete="off"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="phjhsdfh"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.phjhsdfh && form.touched.phjhsdfh }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="phjhsdfh"
                          margin="0"
                          color="accent.900"
                        >
                          Phone number
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.phjhsdfh }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="phjhsdfh"
                        placeholder="Enter your number"
                        variant="filled"
                        focusBorderColor="primary.900"
                        autoComplete="off"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="ejihsdfg"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.ejihsdfg && form.touched.ejihsdfg }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="ejihsdfg"
                          margin="0"
                          color="accent.900"
                        >
                          Email address
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.ejihsdfg }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="ejihsdfg"
                        placeholder="Enter your email"
                        variant="filled"
                        focusBorderColor="primary.900"
                        autoComplete="off"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="sdvvsdfe"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.sdvvsdfe && form.touched.sdvvsdfe }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="sdvvsdfe"
                          margin="0"
                          color="accent.900"
                        >
                          Subject
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.sdvvsdfe }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="sdvvsdfe"
                        placeholder="Enter subject title"
                        variant="filled"
                        focusBorderColor="primary.900"
                        autoComplete="off"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="mdknfken"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.mdknfken && form.touched.mdknfken }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="mdknfken"
                          margin="0"
                          color="accent.900"
                        >
                          Message
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.mdknfken }
                        </FormErrorMessage>
                      </Flex>
                      <Textarea
                        id="mdknfken"
                        placeholder="Enter your message"
                        variant="filled"
                        focusBorderColor="primary.900"
                        autoComplete="off"
                        resize="none"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="name"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      position="absolute"
                      top={ 0 }
                      left={ 0 }
                      w={ 0 }
                      h={ 0 }
                      opacity="0"
                      zIndex={ -1000 }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="njhsdfjk"
                          margin="0"
                          color="accent.900"
                        >
                          Full name
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.njhsdfjk }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="njhsdfjk"
                        placeholder="Enter your name"
                        variant="filled"
                        focusBorderColor="primary.900"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="email"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      position="absolute"
                      top={ 0 }
                      left={ 0 }
                      w={ 0 }
                      h={ 0 }
                      opacity="0"
                      zIndex={ -1000 }
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        margin="0 0 8px"
                      >
                        <FormLabel
                          htmlFor="njhsdfjk"
                          margin="0"
                          color="accent.900"
                        >
                          Full name
                        </FormLabel>
                        <FormErrorMessage
                          margin="0"
                        >
                          { form.errors.njhsdfjk }
                        </FormErrorMessage>
                      </Flex>
                      <Input
                        id="njhsdfjk"
                        placeholder="Enter your name"
                        variant="filled"
                        focusBorderColor="primary.900"
                        { ...field }
                      />
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="tcikjsdf"
                >
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={ form.errors.tcikjsdf && form.touched.tcikjsdf }
                    >
                      <Checkbox
                        id="tcikjsdf"
                        htmlFor="tcikjsdf"
                        size="lg"
                        colorScheme="primary"
                        { ...field }
                      >
                        I accept the <Link as={ GatsbyLink } to="/legal/terms-of-use">Terms of Use</Link> and <Link as={ GatsbyLink } to="/legal/privacy-policy">Privacy Policy</Link>.
                      </Checkbox>
                      <FormErrorMessage>
                        { form.errors.tcikjsdf }
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box>
                  <Button
                    type="submit"
                    colorScheme="primary"
                    size="lg"
                    border="1px"
                    borderColor="primary.900"
                    borderRadius="24px"
                    _hover={{
                      color: 'primary.900',
                      background: 'transparent',
                    }}
                    _focus={{
                      boxShadow: 'none !important'
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
                { formik.status &&
                  <>
                    { formik.status.success &&
                      <Text
                        color="#26702E"
                      >
                        Thank you for your submission, we'll get back to you as soon as we can!
                      </Text>
                    }
                    { !formik.status.success &&
                      <Text
                        color="#991B1B"
                      >
                        Something went wrong. Please contact us directly at&nbsp;
                        <Link
                          href="mailto:info@vesterpest.com"
                          textDecoration="underline"
                          transition="all 0.2s ease-in-out"
                          _hover={{
                            opacity: '0.6'
                          }}
                        >
                          info@vesterpest.com
                        </Link>
                        .
                      </Text>
                    }
                  </>
                }
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}
