import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  HStack
} from "@chakra-ui/react";


export const Legal: React.FC = () => {
  return (
    <HStack
      spacing={{
        base: 4,
        xl: 8
      }}
    >
      <Button
        as={ GatsbyLink }
        to="/legal/terms-of-use"
        variant="link"
        color="accent.900"
        borderRadius="0"
        fontSize="12px"
        fontWeight={ 400 }
        lineHeight="16px"
        _hover={{
          color: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Terms of Use
      </Button>
      <Button
        as={ GatsbyLink }
        to="/legal/privacy-policy"
        variant="link"
        color="accent.900"
        borderRadius="0"
        fontSize="12px"
        fontWeight={ 400 }
        lineHeight="16px"
        _hover={{
          color: 'primary.900',
          textDecoration: 'none'
        }}
        _focus={{
          boxShadow: 'none !important'
        }}
      >
        Privacy Policy
      </Button>
    </HStack>
  )
}
