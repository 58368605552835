import * as React from "react";
import {
  Icon
} from "@chakra-ui/react";


interface Props {
  w?: string
  h?: string
  color?: string
};


export const GeoLocationIcon: React.FC<Props> = ( props ) => {
  return (
    <Icon viewBox="0 0 14 21" { ...props }>
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 0.199951C3.13 0.199951 0 3.32995 0 7.19995C0 12.45 7 20.2 7 20.2C7 20.2 14 12.45 14 7.19995C14 3.32995 10.87 0.199951 7 0.199951ZM7 9.69995C5.62 9.69995 4.5 8.57995 4.5 7.19995C4.5 5.81995 5.62 4.69995 7 4.69995C8.38 4.69995 9.5 5.81995 9.5 7.19995C9.5 8.57995 8.38 9.69995 7 9.69995Z"
      />
    </Icon>
  )
}